import React from 'react';
import Page from "./components/page"

const App = () =>  {
  return (
      <Page/>
  );
}

export default App;
